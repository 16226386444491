

import { onMounted } from "vue"
import { useStore } from "vuex"

export default {
  name: "App",
  setup () {
    const store = useStore()
    onMounted(() => {
      // 刷新时将state中数据保存到本地，防止丢失
      window.addEventListener("pagehide", () => {
        window.localStorage.setItem("stateInfo", JSON.stringify(store.state))
      })
    })

    if (window.localStorage.getItem("stateInfo")) {
      store.replaceState(Object.assign(
        {},
        store.state,
        JSON.parse(window.localStorage.getItem("stateInfo") ?? "")))
    }
  }
}

import { createRouter, createWebHashHistory, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/login/Login.vue"),
    beforeEnter (to: any, from: any, next: any) {
      const { isLogin } = window.localStorage
      if (isLogin) {
        next({ name: "index" })
      } else {
        next()
      }
    }
  }, {
    path: "/index",
    name: "index",
    redirect: "/home",
    meta: {
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "Index" */ "../views/tabbar/Tabbar.vue"),
    children: [{
      path: "/home",
      name: "home",
      meta: {
        keepAlive: true
      },
      component: () =>
        import(/* webpackChunkName: "Home" */ "../views/home/Home.vue")
    }, {
      path: "/mine",
      name: "mine",
      meta: {
        keepAlive: true
      },
      component: () =>
        import(/* webpackChunkName: "Mine" */ "../views/mine/Mine.vue")
    }]
  }, {
    path: "/report",
    name: "report",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/views/home/Report.vue")
  }, {
    path: "/mine/modifyPassword",
    name: "modifyPassword",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/views/mine/ModifyPassword.vue")
  }, {
    path: "/:catchAll(.*)",
    redirect: "/"
  }]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const { isLogin } = window.localStorage
  if (to.name === "login" || isLogin) {
    next()
  } else {
    next({ name: "login" })
  }
})

export default router

import { createStore } from "vuex"

import { UserState } from "@/interface/userModel"

export default createStore<UserState>({
  state: {
    user: Object as any
  },
  mutations: {
    loginSuccess (state, payload) {
      const { user } = payload
      state.user = user
      console.log("++++", state.user)
      window.localStorage.isLogin = true
      window.localStorage.setItem("stateInfo", JSON.stringify(state))
    },
    logout (state, payload) {
      state.user = null as any
      window.localStorage.removeItem("isLogin")
      window.localStorage.removeItem("stateInfo")
    }
  },
  actions: {},
  modules: {}
})

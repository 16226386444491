import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VXETable from "vxe-table"

import {
  Button,
  Field,
  ConfigProvider,
  Tabbar,
  TabbarItem,
  NavBar,
  Swipe,
  SwipeItem,
  DropdownMenu,
  DropdownItem,
  Calendar,
  Toast
} from "vant"
import "vant/lib/index.css"

import "./style/index.scss"
import "xe-utils"
import "vxe-table/lib/style.css"

const app = createApp(App)

app.use(Button)
  .use(Field)
  .use(ConfigProvider)
  .use(Tabbar)
  .use(TabbarItem)
  .use(NavBar)
  .use(Swipe)
  .use(SwipeItem)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Toast)
  .use(Calendar)

app.use(store)
  .use(router)
  .use(VXETable)

app.mount("#app")
